const React = require('react');
const { arrayOf, bool, oneOf, string, shape, number } = require('prop-types');
const useEnhancedReducer = require('../../../hooks/use-enhance-reducer');
const reducer = require('../reducers/variations-modal');

const thunk = require('../../../store/middleware/thunk');
const VariationsModal = require('../modal/variations-modal');
const VariationsBoxContent = require('../box/variations-box-content');
const actions = require('../actions');
const jsonToProps = require('./jsonToProps');

const middlewares = [thunk];

if (process.env.NODE_ENV === 'development') {
  // eslint-disable-next-line n/global-require
  const logger = require('../../../store/middleware/logger');
  middlewares.push(logger);
}

const mapDispatchToProps = (dispatch, initialState) => ({
  resetState: () => dispatch(actions.resetState(initialState)),
  selectQuantity: quantity => dispatch(actions.selectQuantity(quantity)),
  selectVariations: (pickerId, productId, attrId) => dispatch(actions.selectVariations(pickerId, productId, attrId)),
  fetchVariations: (productId, attrId, params) => dispatch(actions.fetchVariations(productId, attrId, params)),
  updateVariationsBox: (productId, attrId, payload) =>
    dispatch(actions.updateVariationsBox(productId, attrId, payload)),
  initFetchVariations: () => dispatch(actions.initFetchVariations()),
  showAddToCartModal: ({ deviceType, itemId }) => dispatch(actions.showAddToCartModal({ deviceType, itemId })),
  updateIframeModal: params => {
    dispatch(actions.updateIframeModal(params));
  },
  bottomSheetClose: () => dispatch(actions.bottomSheetClose()),
  saveFrontendStatsd: data => dispatch(actions.saveFrontendStatsd(data)),
});

const VariantionsWrapper = props => {
  const {
    id,
    app,
    filters,
    selected_attributes,
    pickers,
    picture_config,
    buttonQuantity,
    buyNowAction,
    isBoxWrapper,
    px_variant_id,
    ...restProps
  } = props;

  const initialStateFromProps = {
    id,
    app,
    filters,
    selected_attributes,
    pickers,
    picture_config,
    buttonQuantity,
    buyNowAction,
    px_variant_id,
  };
  const [internalState, dispatch] = useEnhancedReducer(reducer, initialStateFromProps, middlewares);
  const WrappedComponent = isBoxWrapper ? VariationsBoxContent : VariationsModal;
  return (
    <WrappedComponent
      {...restProps}
      {...jsonToProps(internalState)}
      {...mapDispatchToProps(dispatch, initialStateFromProps)}
    />
  );
};

VariantionsWrapper.propTypes = {
  id: string.isRequired,
  selected_attributes: shape({}),
  pickers: arrayOf(
    shape({
      id: string,
      hasDetails: bool,
      label: shape({
        color: string,
        text: string,
      }),
      products: arrayOf(
        shape({
          id: string,
          decoration_type: oneOf(['NONE', 'BLOCKED', 'DISABLED', 'SELECTED']),
          label: shape({
            color: string,
            text: string,
          }),
          picture: shape({
            id: string,
          }),
        }),
      ),
    }),
  ).isRequired,
  picture_config: shape({}).isRequired,
  filters: shape({}),
  app: string.isRequired,
  buttonQuantity: shape({
    max_quantity: number,
    selected_value: number,
  }),
  isBoxWrapper: bool,
  buyNowAction: shape({}),
  px_variant_id: string,
};

VariantionsWrapper.defaultProps = {
  selected_attributes: null,
  filters: null,
  buttonQuantity: null,
  isBoxWrapper: false,
  px_variant_id: null,
};

module.exports = VariantionsWrapper;
