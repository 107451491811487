const {
  FETCH_START,
  FETCH_END,
  FETCH_VARIATIONS_PDP,
  FETCH_VARIATIONS_BOX_PDP,
  FETCH_VARIATIONS_VIP,
  FETCH_VARIATIONS_BOX_VIP,
  FETCH_VARIATIONS_PDS,
  FETCH_VARIATIONS_DETAILS,
  SELECT_VARIATIONS,
  SELECT_QUANTITY,
  RESET_STATE,
  FETCH_ACTION_UPDATE,
  BOTTOMSHEET_MODAL,
  BOTTOMSHEET_MODAL_CLOSE,
  FETCH_ERROR,
  SHOW_SNACKBAR,
} = require('../actions/actions');

const { DECORATION_SELECTED } = require('../utils/types');

const variationsModalFullReducer = (state, action) => {
  switch (action.type) {
    case FETCH_START:
      return {
        ...state,
        isFetching: true,
      };
    case FETCH_END:
      return {
        ...state,
        isFetching: false,
      };
    case FETCH_VARIATIONS_PDP:
      return {
        ...state,
        ...action.payload.components?.variations,
        id: action.payload.id,
      };
    case FETCH_VARIATIONS_BOX_PDP:
      return {
        ...state,
        ...action.payload.components?.outside_variations,
        id: action.payload.id,
      };
    case FETCH_VARIATIONS_PDS:
      return {
        ...state,
        ...action.payload.components.header?.variations,
        id: action.payload.id,
      };
    case FETCH_VARIATIONS_VIP:
      return {
        ...state,
        ...action.payload.components?.variations,
        id: action.payload.id,
        lastAttributeSelected: action.payload.attrId,
        isFetching: false,
        variationId: action.payload.variation_id,
        buttonQuantity: action.payload.components.variations?.button_quantity,
      };
    case FETCH_VARIATIONS_BOX_VIP:
      return {
        ...state,
        ...action.payload.components?.outside_variations,
        id: action.payload.id,
        lastAttributeSelected: action.payload.attrId,
        isFetching: false,
        variationId: action.payload.variation_id,
        buttonQuantity: action.payload.components.outside_variations?.button_quantity,
      };
    case FETCH_VARIATIONS_DETAILS:
      return {
        ...state,
        variationsDetails: action.payload.components?.variations_details,
      };
    case FETCH_ACTION_UPDATE:
      return {
        ...state,
        buyNowAction: action.payload.components.main_actions.form,
      };
    case SELECT_VARIATIONS:
      return {
        ...state,
        id: action.payload.productId,
        lastAttributeSelected: action.payload.attrId,
        selected_attributes: {
          ...state.selected_attributes,
          [action.payload.pickerId]: action.payload.attrId,
        },
        pickers: state.pickers.map(picker => {
          if (picker.id !== action.payload.pickerId) {
            return picker;
          }
          const newSelected = picker.products.find(
            product => product.attribute_id === action.payload.attrId || product.label?.text === action.payload.attrId,
          );
          return {
            ...picker,
            selected_option: {
              ...picker.selected_option,
              text: newSelected ? newSelected.label?.text : picker.selected_option.text,
            },
            products: picker.products.map(product => {
              if (
                (!product.attribute_id && product.id !== action.payload.productId) ||
                (product.attribute_id && product.attribute_id !== action.payload.attrId)
              ) {
                if (product.decoration_type === DECORATION_SELECTED) {
                  return {
                    ...product,
                    decoration_type: 'NONE',
                    selected: false,
                  };
                }
                return product;
              }

              return {
                ...product,
                decoration_type: 'SELECTED',
                selected: true,
              };
            }),
          };
        }),
      };
    case SELECT_QUANTITY:
      return {
        ...state,
        buttonQuantity: {
          ...state.buttonQuantity,
          selected_value: action.payload.quantity,
        },
      };
    case RESET_STATE:
      return {
        ...action.payload.state,
        selected_attributes: {
          ...state.selected_attributes,
        },
      };
    case BOTTOMSHEET_MODAL:
      return {
        ...state,
        iframemodal: {
          ...state.iframemodal,
          ...action.params,
        },
      };
    case BOTTOMSHEET_MODAL_CLOSE:
      return {
        ...state,
        iframemodal: {
          ...state.iframemodal,
          show: false,
          src: null,
          fallbackConfig: null,
          statsdConfig: null,
          sequencerTrack: null,
        },
      };
    case FETCH_ERROR:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    case SHOW_SNACKBAR:
      return {
        ...state,
        snackbar: {
          state: 'VISIBLE',
          message: action.params.message,
          type: action.params.type,
          delay: action.params.delay,
          called_from: action.params.called_from,
          show: true,
        },
      };
    default:
      return state;
  }
};

module.exports = variationsModalFullReducer;
