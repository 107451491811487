const React = require('react');
const { arrayOf, func, bool, oneOf, string, shape, number } = require('prop-types');
const Button = require('@andes/button');
const Snackbar = require('@andes/snackbar');
const { Notification } = require('@andes/badge');
const has = require('lodash/has');
const isEmpty = require('lodash/isEmpty');
const classnames = require('classnames');
const colornames = require('../../../lib/colornames');
const HistoryService = require('../../../services/history');
const BackdropModal = require('../../backdrop-modal');
const getAttributesParam = require('../lib/get-attributes-param');
const IframeModal = require('../../iframe-modal');

const { getIcon } = require('../../action-row/icon-helper');
const VariationsLargeVariant = require('./variants/variations-large-variant');
const VariationsShortVariant = require('./variants/variations-short-variant');
const VariationsSmallPlaceholder = require('../components/variations-small-placeholder');
const ProductDetails = require('../components/product-details');

const StyledLabel = require('../../styled-label');
const IconRuler = require('../../icons/ruler');

const getPictureConfig = require('../lib/get-picture-config');
const { trackEvent } = require('../../../lib/tracking');

const ActionsBuyBox = require('../../buybox/actions/actions-buybox');
const QuantityButton = require('../components/quantity-button');

const namespace = 'ui-pdp-variations';
const { PriceFontSize } = require('../../../lib/num-font-size-enum');

const { DECORATION_BLOCKED, DECORATION_NONE } = require('../utils/types');

const { runSchedulerTask } = require('../../../utils/validators');

const getSelectedProduct = picker => picker.products.find(product => product.selected);
const hasProducts = variationsDetails => has(variationsDetails, 'products') && variationsDetails.products.length > 0;

const handleOnClickVariation = (
  product,
  pickerId,
  selectedAttributes,
  fetchVariations,
  selectOptimistically,
  selectVariations,
  platform,
) => {
  const attributesToReset = product.attributes || [];
  if (selectedAttributes) {
    attributesToReset.forEach(attribute => {
      if (selectedAttributes[attribute]) {
        delete selectedAttributes[attribute];
      }
    });
  }
  if (!product.selected && !selectOptimistically) {
    selectVariations(pickerId, product.id, product.attribute_id || product.label?.text);
    fetchVariations(product.id, product.attribute_id, {
      attributes: getAttributesParam(selectedAttributes, pickerId, product.attribute_id),
      id: product.id,
      platform,
    });
    trackEvent(product.track);
  } else if (!product.selected) {
    selectVariations(pickerId, product.id, product.attribute_id || product.label?.text);
  }
};

const renderLargeVariant = (
  product,
  pickerId,
  has_details,
  selectedAttributes,
  fetchVariations,
  variationsDetails,
  isFetching,
  selectOptimistically,
  selectVariations,
  platform,
  scrollableContainerRef,
  picker,
  index,
) => {
  let productDetails;
  let detailsQuantity = 0;
  if (hasProducts(variationsDetails)) {
    const productDetail = variationsDetails.products.find(detail => detail.id === product.id);
    if (productDetail && has_details) {
      if (productDetail.price) {
        detailsQuantity += 1;
      }
      if (productDetail.stock) {
        detailsQuantity += 1;
      }
      productDetails = <ProductDetails {...productDetail} num_font_size={PriceFontSize.VARIATION_MODAL} />;
    }
  }
  return (
    <VariationsLargeVariant
      ref={scrollableContainerRef}
      onClick={e => {
        e.preventDefault();
        handleOnClickVariation(
          product,
          pickerId,
          selectedAttributes,
          fetchVariations,
          selectOptimistically,
          selectVariations,
          platform,
        );
      }}
      isFetching={isFetching}
      product={product}
      has_details={has_details}
      productDetails={
        has_details && isFetching ? <VariationsSmallPlaceholder rows={detailsQuantity} /> : productDetails
      }
      picture={getPictureConfig(product)}
      ariaLabel={product.label?.accessibility_text ? `${product.label.accessibility_text}` : null}
      index={index}
      picker={picker}
    />
  );
};

const renderBottomSheetModal = (onClose, iframemodal, updateIframeModal, saveFrontendStatsd, bottomSheetClose) => (
  <IframeModal
    {...iframemodal}
    onCloseVariations={onClose}
    updateIframeModal={updateIframeModal}
    bottomSheetClose={bottomSheetClose}
    saveFrontendStatsd={saveFrontendStatsd}
  />
);

const renderShortVariant = (
  product,
  pickerId,
  selectedAttributes,
  fetchVariations,
  selectOptimistically,
  selectVariations,
  platform,
  scrollableContainerRef,
  picker,
  index,
) => (
  <VariationsShortVariant
    ref={scrollableContainerRef}
    product={product}
    index={index}
    ariaLabel={
      product.label?.accessibility_text
        ? `${product.label.accessibility_text}, Boton ${index + 1} de ${picker.products.length}`
        : null
    }
    onClick={e => {
      e.preventDefault();
      handleOnClickVariation(
        product,
        pickerId,
        selectedAttributes,
        fetchVariations,
        selectOptimistically,
        selectVariations,
        platform,
      );
    }}
  />
);

const VariationsModal = ({
  id,
  lastAttributeSelected,
  applyAction,
  submit,
  onClose,
  selected_attributes,
  pickers,
  visible,
  fetchVariations,
  variationsDetails,
  isFetching,
  selectVariations,
  platform,
  actionSelected,
  variationId,
  buttonQuantity,
  selectQuantity,
  isErrorVariation,
  resetState,
  closeModalLabel,
  buyNowAction,
  hasRepresentativePickers,
  msStore,
  form,
  showAddToCartModal,
  iframemodal,
  updateIframeModal,
  saveFrontendStatsd,
  bottomSheetClose,
  snackbar,
}) => {
  const handleApplyButton = () => {
    let selectedProductHistoryData = null;
    if (id) {
      for (let x = 0; x < pickers.length; x += 1) {
        const picker = pickers[x];
        for (let y = 0; y < picker.products.length; y += 1) {
          const product = picker.products[y];
          if (
            (!lastAttributeSelected && product.id === id) ||
            (lastAttributeSelected && product.attribute_id === lastAttributeSelected)
          ) {
            selectedProductHistoryData = {
              path: product.permalink,
              state: { productId: product.id },
            };
          }
        }
      }
      submit(id, selected_attributes, selectedProductHistoryData);
    } else {
      onClose();
    }
  };

  const justOnePickerInteractible = () => {
    const interactiblePickers = pickers.reduce((total, picker) => {
      if (picker.products?.length === 1) {
        return total;
      }
      const hasMoreThanOneOption =
        picker.products.filter(product => product.decoration_type !== DECORATION_BLOCKED).length === 1;
      return hasMoreThanOneOption ? total : total + 1;
    }, 0);
    return interactiblePickers === 1;
  };

  const selectOptimistically =
    isErrorVariation || hasRepresentativePickers ? false : pickers.length === 1 || justOnePickerInteractible();

  const disabled = isFetching || !pickers.every(picker => picker.products.some(product => product.selected));

  const isAvailable = product => product.decoration_type === DECORATION_NONE;

  const isNotCreditAction = action => action.action_type !== 'BUY_NOW_WITH_CREDITS';

  const getFormProps = () => {
    if (platform === 'MS') {
      return {
        context: 'vip-mshops',
        ms_store: msStore,
        channel: 'mshops',
      };
    }
    return {
      context: 'vip',
    };
  };

  const getActionsProp = () => ({
    actions: [{ ...actionSelected, style_blocked: disabled, disabled, type: 'primary', card_tooltip: null }],
    itemId: id,
    form: buyNowAction || {
      ...form,
      item_id: id,
      variation: variationId,
      ...getFormProps(),
    },
    selectedQuantity: buttonQuantity.selected_value,
    className: `${namespace}__modal-form`,
    should_raise_errors: false,
    showAddToCartModal,
  });
  pickers.forEach(picker => {
    if (picker.products.some(isAvailable) && !picker.products.find(product => product.selected)) {
      picker.products.find(isAvailable).isFirstAvailable = true;
    }
  });

  const showError = picker => isErrorVariation && picker.error_message && !getSelectedProduct(picker);

  return (
    <BackdropModal
      onOpen={() => {
        resetState();
        runSchedulerTask(() => {
          HistoryService.replacePathWithHash('variations');
        }, 'background');
      }}
      visible={visible}
      onClose={onClose}
      closeModalLabel={closeModalLabel}
    >
      <div className={`${namespace}__modal`}>
        {pickers.map(picker => {
          const scrollableContainerRef = React.createRef();
          const { id: pickerId, label, selected_subtitle, error_message, selected_option, products, action } = picker;
          return (
            <div className={`${namespace}__modal__variation`} key={pickerId}>
              {label ? (
                <p
                  className={classnames(
                    `${namespace}__modal__label`,
                    {
                      [`${namespace}__modal__label--with-subtitle`]: selected_subtitle,
                    },
                    colornames(label.color),
                    { [colornames(error_message && error_message.label.color)]: showError(picker) },
                  )}
                >
                  {label.text}
                  {isErrorVariation && error_message && !getSelectedProduct(picker) ? (
                    <div className={`${namespace}__modal__error_message`}>
                      <Notification type="error" size="small" />
                      <p className={`${namespace}__modal__error_message__label`}>
                        {error_message.selected_option.text}
                      </p>
                    </div>
                  ) : (
                    selected_option && (
                      <span className={`${namespace}__modal__selected`}>
                        {selected_option.text_icon && getIcon(selected_option.text_icon.id)}
                        <span className={`${namespace}__selected ui-pdp-color--${selected_option.color}`}>
                          {selected_option.text}
                        </span>
                      </span>
                    )
                  )}
                </p>
              ) : null}
              {selected_subtitle && (
                <p className={classnames(`${namespace}__modal__subtitle`, colornames(selected_subtitle))}>
                  {selected_subtitle.text}
                </p>
              )}
              <div className={`${namespace}__modal__variants`} ref={scrollableContainerRef}>
                <ul className={`${namespace}__modal__list`}>
                  {products.map((product, index) => (
                    <li key={product.attribute_id} className={`${namespace}__modal__variant`}>
                      {picker.has_details || (picker.thumbnail || product.picture)
                        ? renderLargeVariant(
                            product,
                            pickerId,
                            picker.has_details,
                            selected_attributes,
                            fetchVariations,
                            variationsDetails,
                            isFetching,
                            selectOptimistically,
                            selectVariations,
                            platform,
                            scrollableContainerRef,
                            picker,
                            index,
                          )
                        : renderShortVariant(
                            product,
                            pickerId,
                            selected_attributes,
                            fetchVariations,
                            selectOptimistically,
                            selectVariations,
                            platform,
                            scrollableContainerRef,
                            picker,
                            index,
                          )}
                    </li>
                  ))}
                </ul>
              </div>
              {action && action.label && (
                <div className={`${namespace}__modal__apparel_size_chart`}>
                  <a href={action.target}>
                    <IconRuler className={`${namespace}__icon-ruler`} />
                    <StyledLabel
                      className={`${namespace}__modal__apparel_size_chart_label`}
                      text={action.modal_title}
                      font_size={action.label.font_size}
                      font_family={action.label.font_family}
                      color={action.icon.color}
                    />
                  </a>
                </div>
              )}
            </div>
          );
        })}
        {isErrorVariation && !isEmpty(actionSelected) ? (
          <div className={`${namespace}__modal__quantity`}>
            {isNotCreditAction(actionSelected) && (
              <QuantityButton {...buttonQuantity} selectQuantity={selectQuantity} />
            )}
            <ActionsBuyBox {...getActionsProp()} />
          </div>
        ) : (
          <Button
            data-testid="apply"
            className={`${namespace}__modal__button`}
            disabled={isFetching}
            onClick={handleApplyButton}
          >
            {applyAction.label.text}
          </Button>
        )}
        {iframemodal?.show &&
          renderBottomSheetModal(onClose, iframemodal, updateIframeModal, saveFrontendStatsd, bottomSheetClose)}
      </div>
      {snackbar && <Snackbar {...snackbar} />}
    </BackdropModal>
  );
};

VariationsModal.propTypes = {
  form: shape({}),
  closeModalLabel: string,
  id: string.isRequired,
  lastAttributeSelected: string,
  applyAction: shape().isRequired,
  selected_attributes: shape({}),
  buyNowAction: shape({}),
  pickers: arrayOf(
    shape({
      id: string,
      hasDetails: bool,
      label: shape({
        color: string,
        text: string,
      }),
      products: arrayOf(
        shape({
          id: string,
          decoration_type: oneOf(['NONE', 'BLOCKED', 'DISABLED', 'SELECTED']),
          label: shape({
            color: string,
            text: string,
          }),
          picture: shape({
            id: string,
          }),
        }),
      ),
    }),
  ).isRequired,
  submit: func.isRequired,
  fetchVariations: func.isRequired,
  visible: bool.isRequired,
  onClose: func.isRequired,
  variationsDetails: shape({}),
  isFetching: bool,
  selectVariations: func.isRequired,
  platform: string,
  actionSelected: shape({
    label: shape({
      text: string.isRequired,
      color: string,
    }).isRequired,
    type: string.isRequired,
    target: string,
    track: shape({}),
    tooltip: shape({}),
  }),
  buttonQuantity: shape({
    max_quantity: number,
    selected_value: number,
  }),
  isErrorVariation: bool,
  resetState: func.isRequired,
  variationId: number,
  selectQuantity: func,
  hasRepresentativePickers: bool,
  msStore: string,
  showAddToCartModal: func.isRequired,
  iframemodal: shape({
    src: string.isRequired,
    show: bool,
    deviceType: string.isRequired,
  }),
  updateIframeModal: func.isRequired,
  saveFrontendStatsd: func.isRequired,
  bottomSheetClose: func.isRequired,
  snackbar: shape({
    delay: number,
    message: string.isRequired,
    type: string,
    show: bool,
  }),
};

VariationsModal.defaultProps = {
  lastAttributeSelected: null,
  isFetching: false,
  selected_attributes: null,
  variationsDetails: null,
  platform: null,
  actionSelected: {},
  buttonQuantity: null,
  isErrorVariation: false,
  variationId: null,
  selectQuantity: null,
  hasRepresentativePickers: false,
  msStore: '',
  iframemodal: {
    show: false,
  },
  snackbar: {
    show: false,
  },
};

module.exports = VariationsModal;
