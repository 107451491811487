const FETCH_START = 'FETCH_START';
const FETCH_END = 'FETCH_END';
const FETCH_ERROR = 'FETCH_ERROR';
const FETCH_VARIATIONS_PDP = 'FETCH_VARIATIONS_PDP';
const FETCH_VARIATIONS_BOX_PDP = 'FETCH_VARIATIONS_BOX_PDP';
const FETCH_VARIATIONS_VIP = 'FETCH_VARIATIONS_VIP';
const FETCH_VARIATIONS_BOX_VIP = 'FETCH_VARIATIONS_BOX_VIP';
const FETCH_VARIATIONS_PDS = 'FETCH_VARIATIONS_PDS';
const FETCH_VARIATIONS_DETAILS = 'FETCH_VARIATIONS_DETAILS';
const SELECT_VARIATIONS = 'SELECT_VARIATIONS';
const SELECT_QUANTITY = 'SELECT_QUANTITY';
const RESET_STATE = 'RESET_STATE';
const FETCH_ACTION_UPDATE = 'FETCH_ACTION_UPDATE';
const BOTTOMSHEET_MODAL = 'BOTTOMSHEET_MODAL';
const BOTTOMSHEET_MODAL_CLOSE = 'BOTTOMSHEET_MODAL_CLOSE';
const SHOW_SNACKBAR = 'SHOW_SNACKBAR';

module.exports = {
  FETCH_START,
  FETCH_END,
  FETCH_ERROR,
  FETCH_VARIATIONS_PDP,
  FETCH_VARIATIONS_BOX_PDP,
  FETCH_VARIATIONS_VIP,
  FETCH_VARIATIONS_BOX_VIP,
  FETCH_VARIATIONS_PDS,
  FETCH_VARIATIONS_DETAILS,
  SELECT_VARIATIONS,
  SELECT_QUANTITY,
  RESET_STATE,
  FETCH_ACTION_UPDATE,
  BOTTOMSHEET_MODAL,
  BOTTOMSHEET_MODAL_CLOSE,
  SHOW_SNACKBAR,
};
