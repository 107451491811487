const getPictureConfig = product =>
  product.picture
    ? {
        alt: product.label?.text,
        id: product.picture?.id,
        src: product.picture.thumbnail?.src,
        src2x: product.picture.thumbnail?.src2x,
      }
    : null;

module.exports = getPictureConfig;
