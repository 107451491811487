const get = require('lodash/get');
const APIService = require('../../../services/api');
const {
  FETCH_START,
  FETCH_END,
  FETCH_VARIATIONS_PDP,
  FETCH_VARIATIONS_BOX_PDP,
  FETCH_VARIATIONS_VIP,
  FETCH_VARIATIONS_BOX_VIP,
  FETCH_VARIATIONS_PDS,
  FETCH_VARIATIONS_DETAILS,
  FETCH_ERROR,
  SELECT_VARIATIONS,
  SELECT_QUANTITY,
  RESET_STATE,
  FETCH_ACTION_UPDATE,
  BOTTOMSHEET_MODAL,
  BOTTOMSHEET_MODAL_CLOSE,
  SHOW_SNACKBAR,
} = require('./actions');

const PDP = 'pdp';
const VIP = 'vip';

const fetchVariations = (id, attrId, params) => (dispatch, getState) => {
  const { app, filters: pdp_filters } = getState();
  const attributes = params?.attributes;
  dispatch({ type: FETCH_START });
  if (app === PDP) {
    APIService.getProductVariations(id, { ...params, app, pdp_filters })
      .then(payload => {
        dispatch({ type: FETCH_VARIATIONS_PDP, payload: { id, ...payload } });
        const pickers = get(payload, 'components.variations.pickers', false);
        if (pickers) {
          const ids = pickers.reduce(
            (acc, picker) => acc.concat(picker.has_details ? picker.products.map(p => p.id) : []),
            [],
          );
          if (ids.length > 0) {
            Promise.all([
              APIService.getProduct(id, { pdp_filters, components_ids: 'main_actions' }),
              APIService.getProductVariationsDetails(id, {
                pdp_filters,
                ids: ids.join(','),
                attributes,
              }),
            ])
              .then(([mainActions, variationsDetails]) => {
                dispatch({ type: FETCH_VARIATIONS_DETAILS, payload: variationsDetails });
                dispatch({ type: FETCH_ACTION_UPDATE, payload: mainActions });
                dispatch({ type: FETCH_END });
              })
              .catch(e => {
                dispatch({ type: FETCH_ERROR, error: e });
              });
          } else {
            dispatch({ type: FETCH_END });
          }
        }
      })
      .catch(e => {
        dispatch({ type: FETCH_ERROR, error: e });
      });
  } else if (app === VIP) {
    APIService.getItemVariations({ id, app, ...params })
      .then(payload => {
        dispatch({ type: FETCH_VARIATIONS_VIP, payload: { attrId, ...payload } });
        dispatch({ type: FETCH_END });
      })
      .catch(e => {
        dispatch({ type: FETCH_ERROR, error: e });
      });
  } else {
    APIService.getSellers(id)
      .then(payload => {
        dispatch({ type: FETCH_VARIATIONS_PDS, payload: { ...payload } });
        dispatch({ type: FETCH_END });
      })
      .catch(e => {
        dispatch({ type: FETCH_ERROR, error: e });
      });
  }
};

const initFetchVariations = () => dispatch => {
  dispatch({ type: FETCH_START });
};

const updateVariationsBox = (id, attrId, responsePayload) => (dispatch, getState) => {
  const { app, filters: pdp_filters } = getState();
  if (app === PDP) {
    dispatch({ type: FETCH_VARIATIONS_BOX_PDP, payload: { id, ...responsePayload } });
    const pickers = get(responsePayload, 'components.outside_variations.pickers', false);
    if (pickers) {
      const ids = pickers.reduce(
        (acc, picker) => acc.concat(picker.has_details ? picker.products.map(p => p.id) : []),
        [],
      );
      if (ids.length > 0) {
        Promise.resolve(APIService.getProduct(id, { pdp_filters, components_ids: 'main_actions' }))
          .then(mainActions => {
            dispatch({ type: FETCH_ACTION_UPDATE, payload: mainActions });
            dispatch({ type: FETCH_END });
          })
          .catch(e => {
            dispatch({ type: FETCH_ERROR, error: e });
          });
      } else {
        dispatch({ type: FETCH_END });
      }
    }
  } else if (app === VIP) {
    dispatch({ type: FETCH_VARIATIONS_BOX_VIP, payload: { attrId, ...responsePayload } });
    dispatch({ type: FETCH_END });
  } else {
    APIService.getSellers(id)
      .then(payload => {
        dispatch({ type: FETCH_VARIATIONS_PDS, payload: { ...payload } });
        dispatch({ type: FETCH_END });
      })
      .catch(e => {
        dispatch({ type: FETCH_ERROR, error: e });
      });
  }
};

const selectVariations = (pickerId, productId, attrId) => dispatch => {
  dispatch({ type: SELECT_VARIATIONS, payload: { pickerId, productId, attrId } });
};

const selectQuantity = quantity => dispatch => {
  dispatch({ type: SELECT_QUANTITY, payload: { quantity } });
};

const resetState = state => dispatch => {
  dispatch({ type: RESET_STATE, payload: { state } });
};

const showAddToCartModal = ({ deviceType, itemId }) => (dispatch, getState) => {
  const {
    buyNowAction,
    app,
    variationId,
    px_variant_id,
    buttonQuantity: { selected_value = null },
    pickers,
  } = getState();
  let data;
  if (buyNowAction) {
    data = buyNowAction;
  } else {
    data = {
      context: app,
      item_id: itemId,
      parent_url: pickers[0]?.permalink,
      quantity: selected_value,
      variation: variationId,
    };
  }
  APIService.addToCartModal({ ...data, px_variant_id }, itemId)
    .then(response => {
      if (response?.data?.shouldOpenModal) {
        dispatch({
          type: BOTTOMSHEET_MODAL,
          params: {
            show: response.data.shouldOpenModal,
            src: response.data.target,
            deviceType,
            isDismissible: response.data.is_dismissible,
          },
        });
      } else if (response?.data?.target) {
        window.location.href = response.data.target;
      } else if (response?.data?.error_message) {
        dispatch({
          type: SHOW_SNACKBAR,
          params: {
            message: response.data.error_message,
            type: 'error',
            delay: 3000,
            called_from: 'add-to-cart',
          },
        });
      }
    })
    .catch(e => {
      dispatch({
        type: SHOW_SNACKBAR,
        params: {
          message: e,
          type: 'error',
          delay: 3000,
          called_from: 'add-to-cart',
        },
      });
    });
};

const updateIframeModal = params => dispatch => {
  dispatch({
    type: BOTTOMSHEET_MODAL,
    params,
  });
};

const bottomSheetClose = () => dispatch => {
  dispatch({ type: BOTTOMSHEET_MODAL_CLOSE });
};

const saveFrontendStatsd = data => (dispatch, getState) => {
  const { app: referer_app } = getState();

  if (!data) {
    return;
  }

  data.tags.referer_app = referer_app;

  APIService.saveFrontendStatsd(data);
};

module.exports = {
  initFetchVariations,
  fetchVariations,
  updateVariationsBox,
  selectVariations,
  selectQuantity,
  resetState,
  showAddToCartModal,
  updateIframeModal,
  bottomSheetClose,
  saveFrontendStatsd,
};
